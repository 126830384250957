@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../checkbox/style/mixin';

@transfer-prefix-cls: ~'@{ant-prefix}-transfer';

.@{transfer-prefix-cls} {
  .reset-component;

  position: relative;

  &-disabled {
    .@{transfer-prefix-cls}-list {
      background: @transfer-disabled-bg;
    }
  }

  &-list {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 200px;
    padding-top: 34px;
    vertical-align: middle;
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base;

    &-with-footer {
      padding-bottom: 34px;
    }

    &-search {
      padding: 0 @control-padding-horizontal-sm;
      &-action {
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        width: 28px;
        color: @disabled-color;
        line-height: @input-height-base;
        text-align: center;
        .@{iconfont-css-prefix} {
          color: @disabled-color;
          transition: all 0.3s;
          &:hover {
            color: @text-color-secondary;
          }
        }
        span& {
          pointer-events: none;
        }
      }
    }

    &-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 6px @control-padding-horizontal;
      overflow: hidden;
      color: @text-color;
      background: @component-background;
      border-bottom: @border-width-base @border-style-base @border-color-split;
      border-radius: @border-radius-base @border-radius-base 0 0;

      &-title {
        position: absolute;
        right: 12px;
      }
    }

    &-body {
      position: relative;
      height: 100%;
      font-size: @font-size-base;

      &-search-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 4px;
      }
    }

    &-body-with-search {
      padding-top: @input-height-base + 8px;
    }

    &-content {
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: auto;
      list-style: none;
      > .LazyLoad {
        animation: transferHighlightIn 1s;
      }

      &-item {
        min-height: 32px;
        padding: 6px @control-padding-horizontal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all 0.3s;
        > span {
          padding-right: 0;
        }
      }

      &-item:not(&-item-disabled):hover {
        background-color: @item-hover-bg;
        cursor: pointer;
      }

      &-item-disabled {
        color: @btn-disable-color;
        cursor: not-allowed;
      }
    }

    &-body-not-found {
      position: absolute;
      top: 50%;
      width: 100%;
      padding-top: 0;
      color: @disabled-color;
      text-align: center;
      transform: translateY(-50%);

      // with filter should offset the search box height
      .@{transfer-prefix-cls}-list-body-with-search & {
        margin-top: @input-height-base / 2;
      }
    }

    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: @border-width-base @border-style-base @border-color-split;
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }
  }

  &-operation {
    display: inline-block;
    margin: 0 8px;
    overflow: hidden;
    vertical-align: middle;

    .@{ant-prefix}-btn {
      display: block;

      &:first-child {
        margin-bottom: 4px;
      }

      .@{iconfont-css-prefix} {
        font-size: 12px;
      }
    }
  }
}

@keyframes transferHighlightIn {
  0% {
    background: @primary-2;
  }
  100% {
    background: transparent;
  }
}
