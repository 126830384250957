@import '../../style/themes/default';
@import '../../style/mixins/index';

@popover-prefix-cls: ~'@{ant-prefix}-popover';

.@{popover-prefix-cls} {
  .reset-component;

  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindex-popover;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;

  &::after {
    position: absolute;
    background: fade(@white, 1%);
    content: '';
  }

  &-hidden {
    display: none;
  }

  // Offset the popover to account for the popover arrow
  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding-bottom: @popover-distance;
  }

  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding-left: @popover-distance;
  }

  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding-top: @popover-distance;
  }

  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom {
    padding-right: @popover-distance;
  }

  &-inner {
    background-color: @popover-bg;
    background-clip: padding-box;
    border-radius: @border-radius-base;
    box-shadow: @box-shadow-base;
    box-shadow: ~'0 0 8px @{shadow-color} \9';
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ */
    &-inner {
      box-shadow: @box-shadow-base;
    }
  }

  &-title {
    min-width: @popover-min-width;
    min-height: 32px;
    margin: 0; // reset heading margin
    padding: 5px @padding-md 4px;
    color: @heading-color;
    font-weight: 500;
    border-bottom: 1px solid @border-color-split;
  }

  &-inner-content {
    padding: 12px @padding-md;
    color: @popover-color;
  }

  &-message {
    position: relative;
    padding: 4px 0 12px;
    color: @popover-color;
    font-size: @font-size-base;
    > .@{iconfont-css-prefix} {
      position: absolute;
      top: 8px; // 4px for padding-top, 4px for vertical middle;
      color: @warning-color;
      font-size: @font-size-base;
    }
    &-title {
      padding-left: @font-size-base + 8px;
    }
  }

  &-buttons {
    margin-bottom: 4px;
    text-align: right;
    button {
      margin-left: 8px;
    }
  }

  // Arrows
  // .popover-arrow is outer, .popover-arrow:after is inner

  &-arrow {
    position: absolute;
    display: block;
    width: sqrt(@popover-arrow-width * @popover-arrow-width * 2);
    height: sqrt(@popover-arrow-width * @popover-arrow-width * 2);
    background: transparent;
    border-style: solid;
    border-width: sqrt(@popover-arrow-width * @popover-arrow-width * 2) / 2;
    transform: rotate(45deg);
  }

  &-placement-top > &-content > &-arrow,
  &-placement-topLeft > &-content > &-arrow,
  &-placement-topRight > &-content > &-arrow {
    bottom: @popover-distance - @popover-arrow-width + 2.2px;
    border-top-color: transparent;
    border-right-color: @popover-bg;
    border-bottom-color: @popover-bg;
    border-left-color: transparent;
    box-shadow: 3px 3px 7px fade(@black, 7%);
  }
  &-placement-top > &-content > &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &-placement-topLeft > &-content > &-arrow {
    left: 16px;
  }
  &-placement-topRight > &-content > &-arrow {
    right: 16px;
  }

  &-placement-right > &-content > &-arrow,
  &-placement-rightTop > &-content > &-arrow,
  &-placement-rightBottom > &-content > &-arrow {
    left: @popover-distance - @popover-arrow-width + 2px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: @popover-bg;
    border-left-color: @popover-bg;
    box-shadow: -3px 3px 7px fade(@black, 7%);
  }
  &-placement-right > &-content > &-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  &-placement-rightTop > &-content > &-arrow {
    top: 12px;
  }
  &-placement-rightBottom > &-content > &-arrow {
    bottom: 12px;
  }

  &-placement-bottom > &-content > &-arrow,
  &-placement-bottomLeft > &-content > &-arrow,
  &-placement-bottomRight > &-content > &-arrow {
    top: @popover-distance - @popover-arrow-width + 2px;
    border-top-color: @popover-bg;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: @popover-bg;
    box-shadow: -2px -2px 5px fade(@black, 6%);
  }
  &-placement-bottom > &-content > &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &-placement-bottomLeft > &-content > &-arrow {
    left: 16px;
  }
  &-placement-bottomRight > &-content > &-arrow {
    right: 16px;
  }

  &-placement-left > &-content > &-arrow,
  &-placement-leftTop > &-content > &-arrow,
  &-placement-leftBottom > &-content > &-arrow {
    right: @popover-distance - @popover-arrow-width + 2px;
    border-top-color: @popover-bg;
    border-right-color: @popover-bg;
    border-bottom-color: transparent;
    border-left-color: transparent;
    box-shadow: 3px -3px 7px fade(@black, 7%);
  }
  &-placement-left > &-content > &-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  &-placement-leftTop > &-content > &-arrow {
    top: 12px;
  }
  &-placement-leftBottom > &-content > &-arrow {
    bottom: 12px;
  }
}
